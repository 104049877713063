<template>
	<main class="main">
		<section class="container">
			<Crumbs :data="crumbs"  v-if="crumbs && crumbs.length > 0"></Crumbs>
			<div
				class="main__box main__box--animation"
				style="padding-bottom:0px;padding-top:50px;"
				v-if="blocks"
			>
				<h1 class="main__title">
					{{ blocks.title }}
				</h1>
				<div class="main__text-box">
					<div class="main__text editor" v-html="blocks.content"></div>
				</div>
				<div class="main__animation-box">
					<div class="main__animation-bg animation-pattern">
						<img
							src="@/assets/images/animation/animation_circle-pattern.svg"
							alt="Фон анимации"
							aria-hidden="true"
						/>
					</div>
					<!--@/assets/images/pictures/post-image-bankcard.png-->
					<div class="main__animation-front animation-icon bg-image-partner">
						<img
							:src="blocks.image"
							alt="Картинка с анимацией"
							aria-hidden="true"
						/>
					</div>
				</div>
			</div>
		</section>

		<section class="container">
			<PartnersBonus
				:data="listLoyaltyPrograms"
				v-if="listLoyaltyPrograms"
			></PartnersBonus>
		</section>
	</main>
</template>

<script>
import Crumbs from '@/components/Crumbs.vue'
import PartnersBonus from '@/components/PartnersBonus.vue'
import Axios from 'axios'
export default {
	name: 'PartnerProgram',
	components: {
		Crumbs,
		PartnersBonus,
	},

	data() {
		return {
			crumbs: null,
			blocks: null,
			//data: null,
		}
	},

	computed: {
		listLoyaltyPrograms() {
			return {
				title: 'Партнеры программы лояльности',
				link: 'PartnerProgram',
				filterDispatch: 'SET_LIST_APPLIED_ACCEPTOR_FILTERS',
				filterGetters: 'LIST_APPLIED_ACCEPTOR_FILTERS',
				filterAll: true,
				list: this.$store.getters.LIST_ACCEPTORS,
			}
		},
	},

	mounted() {
		this.$store.dispatch('GET_LIST_ACCEPTORS')

		this.crumbs = [
			{
        link: 'HolderHome',
        text: 'Главная',
			},
			{
				link: null,
				text: 'Партнеры и акции',
			},
		]
	},
	created() {
		let self = this
        let query = {
			page: this.$route.fullPath,
			block: this.$options.name,
		}
		Axios({
			url: `${process.env.VUE_APP_API_VERSION}/block?page=${query.page}`,
			method: 'GET',
		})
		.then(response => {
			self.blocks = response.data[0]
		})
		.catch(err => {
			console.log(err)
		})
	},
}
</script>
<style scoped>
@media (max-width: 1180px) {
		.main__animation-front.animation-icon img {
			width: auto;
		}
	.main__box {
		padding-top: 0px;
		}
}
@media (max-width: 767px) {
		.main__animation-front.animation-icon img {
		display: none;
	}
}
</style>